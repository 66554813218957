










































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  onMounted,
  toRef,
  nextTick
} from '@nuxtjs/composition-api';
import {
  SfReview,
  SfTabs,
  SfLoader,
} from '@storefront-ui/vue';
import ProductAddReviewForm from '~/modules/catalog/product/components/ProductAddReviewForm.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import reviewGetters, {
  getReviewId,
  getReviewAuthor,
  getReviewDate,
  getReviewMessage,
  getReviewRating,
} from '~/modules/review/getters/reviewGetters';
import { useReview, UseReviewAddReviewParams } from '~/modules/review/composables/useReview';
import { Product } from '~/modules/catalog/product/types';
import { TabsConfig } from '~/modules/catalog/product/composables/useProductTabs';
import { usePageStore } from '~/stores/page';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

// Extend the Window interface
declare global {
  interface Window {
    ccs_cc_args: Array<[string, string]>;
  }
}

export default defineComponent({
  name: 'ProductTabs',
  components: {
    ProductAddReviewForm,
    HTMLContent,
    SfReview,
    SfTabs,
    SfLoader,
    SkeletonLoader
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product | null>,
      default: null,
    },
    openTab: {
      type: Number,
      default: TabsConfig.description.ID,
    },
  },
  setup(props, { emit }) {
    const { routeData } = usePageStore();
    const reviews = ref(null);
    const isReviewsLoading = ref(true);
    const disicon = '<svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 472.37"><path fill-rule="nonzero" d="M145.33 172.6l57.21-.75c2.76-.04 5.33.74 7.51 2.1 11.85 6.86 23.04 14.67 33.45 23.48 5.86 4.97 11.53 10.28 16.99 15.96 19.67-30.56 43.04-61.58 67.52-90.78 31.9-38.04 65.91-73.2 96.22-100.37 2.65-2.37 5.97-3.53 9.26-3.53l44.22-.07c7.7 0 13.95 6.25 13.95 13.95 0 3.86-1.56 7.34-4.09 9.87-40.58 45.12-82.2 96.78-119.92 149.72-34.92 49.02-66.55 99.17-90.93 146.26-3.52 6.83-11.92 9.51-18.75 5.99a13.796 13.796 0 01-6.23-6.5c-13.36-28.57-29.28-54.8-48.23-78.2-18.93-23.37-41-44.09-66.69-61.72-6.35-4.33-7.98-13-3.65-19.35 2.82-4.14 7.49-6.27 12.16-6.06zM62.55 0h270.16c-19.14 19.72-35.72 38.96-49.97 57.45H62.55c-1.42 0-2.71.57-3.64 1.46a5.27 5.27 0 00-1.46 3.64v347.26c0 1.34.6 2.6 1.54 3.55.96.95 2.23 1.56 3.56 1.56h386.89c1.29 0 2.55-.62 3.52-1.58.97-.97 1.59-2.24 1.59-3.53V213.59c20.82-8.61 40.4-17.48 57.45-25.81v222.03c0 17.14-7.11 32.82-18.43 44.14-11.33 11.33-26.99 18.42-44.13 18.42H62.55c-17.13 0-32.83-7.06-44.17-18.4C7.08 442.67 0 427.03 0 409.81V62.55C0 45.4 7.04 29.78 18.35 18.46l.11-.11C29.78 7.04 45.4 0 62.55 0z"></path></svg>';

    const {
      search: searchReviews,
      addReview,
    } = useReview();
    const product = toRef(props, 'product');

    const productSKU = props.product?.sku || '';
    const productMF = props.product?.manufacturer_name || '';
    const productPN = props.product?.manufacturer_part_no || '';
    onMounted(async () => {
      if (process.client && product.value) {
        setTimeout(function () {
          var existingshopperapprovedScript = document.querySelector('script[data-product-detail-test="shopperapproved-product-detail-page"]');
          if (existingshopperapprovedScript) {
            existingshopperapprovedScript.parentNode.removeChild(existingshopperapprovedScript);
          }
          var sa_product = product.value.sku;
          let shopper = document.createElement('script');
          shopper.setAttribute('src', "https://www.shopperapproved.com/product/31975/" + product.value.sku + ".js");
          shopper.setAttribute('data-product-detail-test', "shopperapproved-product-detail-page");
          document.head.appendChild(shopper);
        }, 2000);
      }
      if (product.value) {
        setTimeout(function () {
          var abWidgetsrc = "https://mpex.services.answerbase.com/javascript/widget/full-featured-widget.min.js"
          var existingScript = document.querySelector('script[src="' + abWidgetsrc + '"]');

          if (existingScript) {
            existingScript.parentNode.removeChild(existingScript);
          }
          var tempDiv = document.createElement('div');
          tempDiv.innerHTML = product.value.description ? product.value.description.html : '';
          var sa_product_description_250 = tempDiv.textContent.trim().replace(/\s+/g, ' ');
          var sa_product_description = sa_product_description_250.slice(0, 250);
          var abjs = window.document.createElement("script");
          abjs.src = abWidgetsrc;
          abjs.type = "text/javascript";
          abjs.setAttribute('data-product-external-id', String(product.value.id) || '');
          abjs.setAttribute('data-product-id', String(product.value.id) || '');
          abjs.setAttribute('data-product-sku', String(product.value.sku) || '');
          abjs.setAttribute('data-product-title', String(product.value.name) || '');
          abjs.setAttribute('data-product-price', String(product.value.price_range ? product.value.price_range.maximum_price.final_price.value : ''));
          abjs.setAttribute('data-product-url', product.value ? product.value.url_key + ".html" : '');
          abjs.setAttribute('data-product-description', sa_product_description);
          abjs.setAttribute('data-product-image-url', product.value.thumbnail ? product.value.thumbnail.url : '');
          abjs.setAttribute('data-insert-after', "#abqa-widget");
          abjs.setAttribute('defer', "");
          document.getElementsByTagName("head")[0].appendChild(abjs);
        }, 2000);
      }

      if (productMF == 'Sony') {
        await nextTick(); // Ensure DOM is ready

        console.log('Initializing Sony rich media script');
        
        // Define ccs_cc_args globally on the window object
        window.ccs_cc_args = [
            ['cpn', productSKU],
            ['mf', productMF],
            ['pn', productPN],
            ['upcean', '027242919013'],
            ['lang', 'EN'],
            ['market', 'US'],
            ['_SKey', 'c6ae5970'],
            ['_ZoneId', '790ee5e864'],
        ];

        const sc = document.createElement('script');
        sc.type = 'text/javascript';
        sc.async = true;
        sc.src = `${document.location.protocol === 'https:' ? 'https://' : 'http://'}cdn.cs.1worldsync.com/jsc/h1ws.js`;

        sc.onload = () => {
            console.log('Sony rich media script loaded');
        };

        sc.onerror = (e) => {
            console.error('Failed to load the Sony rich media script', e);
        };

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode?.insertBefore(sc, firstScript);
      }
    });

    const getSearchQuery = () => ({
      filter: {
        sku: {
          eq: props.product ? props.product.sku : '',
        },
      },
    });

    const fetchReviews = async (query = getSearchQuery()) => {
      const productReviews = await searchReviews(query);
      const baseReviews = Array.isArray(productReviews)
        ? productReviews[0]
        : productReviews;

      reviews.value = reviewGetters.getItems(baseReviews);
    };

    let lastReviewsQuery = '';
    const changeTab = (tabNumber: number) => {
      if (!process.client) return props.openTab;
      const tabs = document.querySelector('#tabs');

      if (!tabs) return props.openTab;
      /* tabs.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      }); */

      if (tabNumber === TabsConfig.reviews.ID) {
        const newQuery = getSearchQuery();
        const stringNewQuery = JSON.stringify(newQuery);
        if (lastReviewsQuery !== stringNewQuery) {
          lastReviewsQuery = stringNewQuery;
          fetchReviews(newQuery);
          isReviewsLoading.value = false;
        }
      }
      emit('changeTab', tabNumber);
      return tabNumber;
    };

    /* const activeTab = computed(() => changeTab(props.openTab)); */

    const productDescription = computed(
      () => props.product?.description?.html || '',
    );

    const productSpecifications = computed(
      () => props.product || '',
    );

    const successAddReview = async (reviewData: UseReviewAddReviewParams) => {
      await addReview(reviewData);
      document.querySelector('#tabs').scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    };

    const customactivetab = ref(1);

    if (productDescription && props.product.tab_specs_dims && props.product.tab_box) {
      customactivetab.value = 1;
    } else if (productDescription && props.product.tab_box) {
      customactivetab.value = 1; 
    } else if (productDescription && props.product.tab_specs_dims) {
      customactivetab.value = 1; 
    } else if (productDescription) {
      customactivetab.value = 1;
    } else {
      customactivetab.value = 1;
    }
    

    const activeTab = computed((tab) => {
      return customactivetab.value;
    });

    const activeTabChanged = (newActiveTab) => {
      const customAttribute = newActiveTab.title;      
      return customAttribute;
    };

    const tab_nav_clicked = (tab) => {
      customactivetab.value = tab;
    };

    return {
      reviews,
      activeTab,
      changeTab,
      isReviewsLoading,
      getReviewId,
      getReviewAuthor,
      getReviewDate,
      getReviewMessage,
      getReviewRating,
      productDescription,
      successAddReview,
      productSpecifications,
      disicon,
      customactivetab,
      activeTabChanged,
      tab_nav_clicked,
      productSKU,
      productMF,
      productPN,
    };
  },
});
